import React, { Component } from 'react';
import TopProducts from '../components/TopProducts';
import NewsSidebar from '../components/NewsSidebar';



class About extends Component {


   

    
    render(){
        return(
            <div>
                <div className="left-content">
                    <TopProducts />
                    <NewsSidebar />
                </div>
                <div className="right-content">
                    <h2>
                        O nama
                    </h2>
                    <br />
                    <p>
                        Harmonija svojim radom želi da pruži pomoć i nadahnuće svojim čitaocima na putu lične transformacije i postizanja zdravlja i blagostanja u svim područjima života. Objavljivanjem knjiga koje nude jedinstven pogled na zdravlje, sveopšte blagostanje, način života, ishranu i lični razvoj, kao i na odnose s drugim ljudima, roditeljstvo i duhovnost.
                    </p>
                    <br />
                    <p>
                        Harmonija daje podršku čitaocima u njihovoj želji da postanu najbolja moguća verzija sebe, kako spolja, tako i iznutra. 
                    </p>
                </div>
            </div>
        );
    }

}

export default About;