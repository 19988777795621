import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import {Config} from '../components/Config';


class Pager extends Component {

    constructor(props){
        super(props);
        this.state = {
            numberOfProducts: 0,
            pageUpNumber: 0,
            pageDownNumber: 0,
            numberOfPages: 0
        }
    }
 
    componentDidMount(){
        setTimeout(()=>{
            this.setState({pageUpNumber: this.pageUp(), pageDownNumber: this.pageDown(), numberOfPages: this.getNumberOfPages()},()=>{});
        }, 10)
    }

    componentWillReceiveProps(nextProps){
        setTimeout(()=>{
            this.setState({pageUpNumber: this.pageUp(), pageDownNumber: this.pageDown(), numberOfPages: this.getNumberOfPages()},()=>{});
        }, 10)
    }

    getNumberOfPages=()=>{
        return Math.ceil(this.props.numberOfProducts/this.props.perPage);
    }

    pageUp=()=>{
        let cp = parseInt(this.props.currentPage);
        cp += 1;
        if(cp > this.getNumberOfPages()){
            cp = 1;
        }
        return cp;
    }

    pageDown=()=>{
        let cp = parseInt(this.props.currentPage);
        cp -= 1;
        if(cp < 1){
            cp = this.getNumberOfPages();
        }
        
        return cp;
    }

    render() {
        return (
            
                <div className="pages">
                    { this.state.numberOfPages > 1 &&
                    <div>
                    <a href={`${window.location.href.split('?')[0]}?page=${this.state.pageDownNumber}`} ><button className="paging-button"> {'<'} </button></a> 
                        <span>{this.props.currentPage}</span> / {this.state.numberOfPages} {/* {Math.ceil(this.props.numberOfProducts / this.props.perPage)} */}
                    <a href={`${window.location.href.split('?')[0]}?page=${this.state.pageUpNumber}`} ><button className="paging-button"> {'>'} </button></a> 
                    </div>
                    }
                </div>
            
        );
    }

}

export default Pager;
