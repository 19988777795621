import React, { Component } from 'react';
import TopProducts from '../components/TopProducts';
import NewsSidebar from '../components/NewsSidebar';



class Message extends Component {

    constructor(props) {
        super(props);
    }
    messages = {
        registered: 'Uspešno ste registrovani. Molimo vas da proverite vaš email i da aktivirate nalog.',
        krcko: 'Uspešno ste se pretplatili na časopis Krcko.',
        passwordReset: 'Nalog za resetovanje lozinke je poslat. Molimo vas da proverite vas mejl kako biste promenili lozinku.',
        passwordChanged: 'Vaša lozinka je uspešno promenjena.'
    }

    render(){
        return(
            <div>
                <p className="message">{this.messages[this.props.match.params.message]}</p>
            </div>
        );
    }

}

export default Message;