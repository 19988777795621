import React, { Component } from 'react';
//import ProductView from '../components/ProductView';
import Slider from "react-slick";
import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import renderHTML from 'react-render-html';
import Service from '../components/Service';

function stripHTML(s){
    let n = s.replace(/(<([^>]+)>)/ig,"");
    let a = n.split('&nbsp;');
    return a.join(' ');
}


function images(image){
    return 'http://api.pp.cekaonica.com/media/news/'+ image;
}

const sizes = {
    1: '100%',
    2: '50%',
    3: '33%',
    4: '25%',
    5: '20%'
}

class NewsSlider extends Component {

    render() {
    // var settings = {
    //   dots: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   slidesToShow: this.props.perPage,
    //   slidesToScroll: this.props.perPage
    // };

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, //this.props.perPage,
        slidesToScroll: 3, //this.props.perPage,
        autoplay: true,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
      <Slider {...settings}>
          {this.props.news.map(function(item, index){
                        return(
                            // <li key={index} style={{width: sizes[this.props.perPage]}}>
                            //<ProductView item={item} key={index} />
                            // </li>
                            <div className='news-slider-container'>
                                <div>
                                    {/* <Link to={`/vest/${item.id}`}><img src={images(item.image)}  /></Link> */}
                                    <Link to={`/vest/${item.id}`}><div className="image" style={{ backgroundImage: "url(" + Service.images('news/'+item.image) + ")"}}  /></Link>
                                    <h3>{item.title}</h3> 
                                    <p>{stripHTML(item.text.slice(0, 75))}...</p> 
                                </div>
                            </div> 
                        )
                    },this)
                    }
        {/* <div>
          <h3>1</h3>
        </div>
        <div>
          <h3>2</h3>
        </div>
        <div>
          <h3>3</h3>
        </div>
        <div>
          <h3>4</h3>
        </div>
        <div>
          <h3>5</h3>
        </div>
        <div>
          <h3>6</h3>
        </div> */}
      </Slider>
    );
  }


}

export default NewsSlider;
