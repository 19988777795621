import React, { Component } from 'react';
import {Cart} from '../components/Cart';
import Service from '../components/Service';


function images(image){
    return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
}

class CartProduct extends Component {

    product = this.props.product.product;

    removeProduct=(productId)=>{
        Cart.removeFromCart(productId, ()=>{
            this.props.updateCart();
        });
        
    }

    shouldComponentUpdate() {
        return true;
    }

    changeQuantity = (product, value) => {
        if(value === 'add'){
            Cart.addToCart(product);
        }

        if(value === 'remove'){
            Cart.removeOneFromCart(product);
        }

        this.props.updateCart();
    }

    render() {
        return(
            <div className="cart-product">
                <div className="image"><img src={Service.images(this.product.slug + '.jpg')} /></div>
                <div className="title">{this.product.title} {this.product.id}</div>
                <div className="quantity">
                    <button onClick={()=>{this.changeQuantity(this.product, 'remove')}}>-</button>
                    <span>{this.props.product.quantity}</span>
                    <button onClick={()=>{this.changeQuantity(this.product, 'add')}}>+</button>
                </div>
                <div className="price">{this.product.price}</div>
                <div className="trash"><button onClick={()=>{this.removeProduct(this.product.id)}}>X{this.product.id}</button></div>
                {/* <p> {this.props.product.id} | kom: {this.props.product.value}</p> */}
            </div>
        )
    }
}

export default CartProduct;