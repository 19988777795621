import React, { Component } from 'react';

import Categories from '../components/Categories';


class Shipping extends Component{

    render(){
        return(
            <div>
                <div className="left-content">
                    {/* <h3>Kategorije</h3> */}
                    <Categories />
                </div>
                <div className="right-content">
                    <h2>
                        Dostava
                    </h2>
                    <h3>
                        Troškovi isporuke i načini dostave:
                    </h3>
                    <p>
                        Knjige je trenutno moguće slati jedino na teritoriji Republike Srbije.
                        <br />
                        Troškovi dostave: Za naručene knjige u vrednosti do 2000 dinara troškovi iznose 180 dinara, a za porudžbine preko 2000 dinara dostava je besplatna.
                        <br />
                        Pouzećem - Naručene artikle dobijate na kućnu adresu, a troškove narudžbine plaćate poštaru;
                    </p>
                    <h3>
                        Procena roka za isporuku robe
                    </h3>
                    <p>
                        U našem i Vašem interesu jeste da se isporuka izvrši u najbržem roku. Nakon što ste potvrdili porudžbinu, u najkraćem mogućem roku kontaktiraće Vas naš operater telefonom, radi potvrde porudžbine i vaših podataka. Period tokom kojeg će Vas naš prodavac kontaktirati je najčešće do 2 radna dana i dobićete detaljna uputstva za isporuku. Za sve porudžbine poslate petkom i vikendom, prodavac će Vas kontaktirati počev od naredne nedelje. S obzirom na to, nakon slanja narudžbine u internet prodavnici, isporuku robe možete očekivati u sledećim vremenskim intervalima:
                    </p>
                    <h3>
                        Porudžbine iz Srbije
                    </h3>
                    <p>
                        Isporuka kurirskom službom vrši se u roku od 1 do 3 dana.
                    </p>
                </div>
            </div>
        );
    }

}

export default Shipping;