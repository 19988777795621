import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import duix from 'duix';

import ReactGA from 'react-ga';

var validator = require("email-validator");
// !validator.validate(this.state.email)

class Register extends Component{

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            nameError: 'none',
            email: '',
            emailError: 'none',
            password: '',
            passwordError: 'none',
            passwordConfirm: '',
            passwordConfirmError: 'none',
            loginEmail: '',
            loginEmailError: 'none',
            loginPassword: '',
            loginPasswordError: 'none',
            subscribe: true,
            toggleResetPassword: false,
            resetPasswordEmail: '',
            loginErrorMessage: ''
        }
    }

    componentDidMount(){
        ReactGA.initialize(Service.gaCode);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    // register
    updateName = (event) => {
        this.setState({name: event.target.value});
    }
    updateEmail = (event) => {
        this.setState({email: event.target.value});
    }
    updatePassword = (event) => {
        this.setState({password: event.target.value});
    }
    updatePasswordConfirm = (event) => {
        this.setState({passwordConfirm: event.target.value});
    }
    updateSubscribe = (event) => {
        this.setState({subscribe: event.target.checked});
    }

    register = () => {
        if(!this.checkRegisterInputs()){
            alert('Niste uneli sve podatke.');
            return;
        }
        Service.register(this.state.email, this.state.password, this.state.name, this.state.subscribe, (res)=>{
            //duix.set('', 1);
            if(res.success){
                duix.set('registration', 1);
                this.props.history.push('/message/registered');
            }else{
                if(res.info == 'email already exists'){

                    alert('Greška. Ovaj mejl je vec iskorišćen.');
                }else{
                    alert('Registracija nije uspela, pokušajte ponovo.');
                }
            }
        });
    }

    // login
    updateLoginEmail = (event) => {
        this.setState({loginEmail: event.target.value});
    }
    updateLoginPassword = (event) => {
        this.setState({loginPassword: event.target.value});
    }

    checkLoginInputs(){
        let check = true;
        if(!validator.validate(this.state.loginEmail)){
            this.setState({loginEmailError: 'block'});
            check = false;
        }else{
            this.setState({loginEmailError: 'none'});
        }
        if(!this.state.loginPassword){
            this.setState({loginPasswordError: 'block'});
            check = false;
        }else{
            this.setState({loginPasswordError: 'none'});
        }


        if(check){
            return true;
        }

    }

    checkRegisterInputs(){
        let check = true;

        if(!this.state.name){
            this.setState({nameError: 'block'});
            check = false;
        }else{
            this.setState({nameError: 'none'});
        }

        if(!validator.validate(this.state.email)){
            this.setState({emailError: 'block'});
            check = false;
        }else{
            this.setState({emailError: 'none'});
        }

        if(!this.state.password){
            this.setState({passwordError: 'block'});
            check = false;
        }else{
            this.setState({passwordError: 'none'});
        }

        if(this.state.passwordConfirm !== this.state.password){
            this.setState({passwordConfirmError: 'block'});
            check = false;
        }else{
            this.setState({passwordConfirmError: 'none'});
        }


        if(check){
            return true;
        }

    }

    login = () => {
        if(!this.checkLoginInputs()){
            return;
        }

        this.setState({loginErrorMessage: ''});
        Auth.email = this.state.loginEmail;
        Auth.authenticate(this.state.loginPassword, () => {
            // redirect to welcome page home page in this case
            //this.props.updateRegistration();
            //duix.set('', 1);
            duix.set('registration', 1);
            this.props.history.push('/');
        },
        (message)=>{ //login failure
            this.setState({loginErrorMessage: message});
        })
    }

    signout = () => {
        // Auth.logout((result)=>{
        //     // redirect to welcome page home page in this case
        //     duix.set('wishlistItems', 1);
        //     this.props.updateRegistration();
        //     this.props.history.push('/');
        // });
    }

    resetPassword=()=>{
        this.setState({toggleResetPassword: !this.state.toggleResetPassword});
    }

    updateResetPasswordEmail=(event)=>{
        this.setState({resetPasswordEmail: event.target.value});
    }

    submitResetPassword=()=>{
        Service.get('/users/resetPassword',{email: this.state.resetPasswordEmail},(res)=>{
            if(res.success){
                this.props.history.push('/message/passwordReset');
            }
        })
    }

    messages = {
        success: 'Vaša nalog je aktiviran. Molimo vas da se ulogujete.'
    }

    render(){
        return(
            <div className="registration">
                <p>{this.messages[this.props.match.params.message]}</p>
                <div>
                    <h2>Prijava</h2>
                    <label>email:</label>
                    <input value={this.state.loginEmail} onChange={this.updateLoginEmail} />
                    <p className="error" style={{display: this.state.loginEmailError}}>* morate da unesete validnu email adresu.</p>
                    <label>šifra:</label>
                    <input type="password" value={this.state.loginPassword} onChange={this.updateLoginPassword} />
                    <p className="error" style={{display: this.state.loginPasswordError}}>* morate da unesete validnu email adresu.</p>
                    <button onClick={this.login}>Uloguj se</button>
                    <label className="error">{this.state.loginErrorMessage}</label>
                    <p>Ako ste zaboravili šifru <button onClick={this.resetPassword}>kliknite ovde</button></p>
                    { this.state.toggleResetPassword === true &&
                        <div>
                            <p>Unesite vaš email kako biste resetovali šifru.</p>
                            <input type="text" value={this.state.resetPasswordEmail} onChange={this.updateResetPasswordEmail} />
                            <button type="button" onClick={this.submitResetPassword}>Pošalji</button>
                        </div>
                    }
                </div>
                <div>
                    <h2>Registracija</h2>
                    <label>ime i prezime:</label>
                    <input value={this.state.name} onChange={this.updateName} />
                    <p className="error" style={{display: this.state.nameError}}>* morate da unesete ime i prezime</p>
                    <label>email:</label>
                    <input value={this.state.email} onChange={this.updateEmail} />
                    <p className="error" style={{display: this.state.emailError}}>* morate da unesete validnu email adresu.</p>
                    <label>šifra:</label>
                    <input type="password" value={this.state.password} onChange={this.updatePassword} />
                    <p className="error" style={{display: this.state.passwordError}}>* morate da unesete šifru</p>
                    <label>ponovi šifru:</label>
                    <input type="password" value={this.state.passwordConfirm} onChange={this.updatePasswordConfirm} />
                    <p className="error" style={{display: this.state.passwordConfirmError}}>* šifre nisu identicne!</p>
                    <p>
                        <input type="checkbox" name="subscribe" checked={this.state.subscribe} onChange={this.updateSubscribe} /> 
                         Želim da primam emailove o izdanjima.
                    </p>
                    <button onClick={this.register}>Registruj se</button>
                </div>
            </div>
        );
    }

}

export default Register;