import React, { Component,Fragment } from 'react';
import { Cart } from '../components/Cart';
import Categories from '../components/Categories';
import Service from '../components/Service';
import StarRatings from 'react-star-ratings';
import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import {Auth} from '../components/Auth';
import {Config} from '../components/Config';
import Pager from '../components/Pager';
import duix from 'duix';
import ReactPaginate from 'react-paginate';
import Sticker from '../components/Sticker';

import ReactGA from 'react-ga';


const queryString = require('query-string');

function capitalizeFirstLetterCategory(string) {
    if(string){
        let check = parseInt(string.charAt(0));
        console.log(check);
        if(isNaN(check)){
            let r = string.replace(/-/g,' ');
            console.log('e', r);
            return r.charAt(0).toUpperCase() + r.slice(1);
        }
        return string;
    }

    return 'Knjige';
}



// const Info = props => (
  
//     <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
 
// );

// function images(image){
//     return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
//   }

class Products extends Component {

    

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            numberOfProducts: 0,
            page: 1,
            perPage: Config.perPage,
            currentFilter: '',
            pageUpNumber: 0,
            pageDownNumber: 0,
            noProductsMessage: 'Učitavanje'
        }
        
    }

    

    componentDidMount=() =>{

        ReactGA.initialize(Service.gaCode);
        ReactGA.pageview(window.location.pathname + window.location.search);
        if(this.getParams.page){
            this.page = parseInt(this.getParams.page);
        }else{
            this.page = 1;
        }

        this.updateProducts();

    }

    componentWillReceiveProps(){
        this.updateProducts();
    }


    addToCart=(product)=>{
      Cart.addToCart(product);
      Cart.getCart();
    }

    removeOneFromCart(product){
      Cart.removeOneFromCart(product);
      Cart.getCart();
    }
  
    removeFromCart(product){
      Cart.removeFromCart(product);
      Cart.getCart();
    }

    getParams = queryString.parse(this.props.location.search);
    page;

    sortProducts(products){
        // let p = products;
        let a = [];
        let b = [];
        for(var i in products){
            if(products[i].status >= 3){
                b.push(products[i])
            }else{
                a.push(products[i])
            }
        }
        a.sort((a, b) => (a.published_at < b.published_at) ? 1 : -1);
        b.sort((a, b) => (a.published_at < b.published_at) ? 1 : -1);

        return a.concat(b);
    }
    addToWishlist(product){

        if(Auth.isAuthenticated){
            Service.addToWishlist({
                productId: product.id, 
                Auth
                },()=>{
                    duix.set('wishlistItems', 1);
                    duix.set('modal', 'Proizvod je dodat u listu želja.');
                });
            }else{
                alert('Morate biti ulogovani da biste koristili listu želja.');
            }
        }
    


    updateProducts = () => {
        // get products without any filter
        if(!this.props.match.params.filter){
            if(this.state.currentFilter !== 'default'){
                this.state.currentFilter = 'default';
                this.setState({page: 1})
            }
            // let getParams = queryString.parse(this.props.location.search);
            Service.get('/products/get',{page: this.page, perPage: this.state.perPage},(res)=>{
                let p = res.data.products;
                p.sort((a, b) => (a.published_at < b.published_at) ? 1 : -1);
                //p.sort((a, b) => (a.status > b.status) ? 1 : -1);
                this.setState({products: p});
                this.setState({numberOfProducts: res.data.total_products});
                this.setState({noProductsMessage: 'Nema pronađenih proizvoda!'});
                // this.setState({pageUpNumber: this.pageUp(), pageDownNumber: this.pageDown()},()=>{
                // });
            });
            return;
        }
        
        // get products by search
        if(this.props.match.params.filter === 'filter'){
            if(this.state.currentFilter !== 'filter'){
                this.state.currentFilter = 'filter';
                this.setState({page: 1})
            }
            Service.getProductBySearch({filter: this.props.match.params.a, perPage: this.state.perPage, page: this.page},(res)=>{
                let p = this.sortProducts(res.data.products);
                //p.sort((a, b) => (a.published_at < b.published_at) ? 1 : -1);
                //p.sort((a, b) => (a.status > b.status) ? 1 : -1);
                this.setState({products: p});
                this.setState({numberOfProducts: res.data.total_products});
                // this.setState({pageUpNumber: this.pageUp(), pageDownNumber: this.pageDown()});
            });
            return;
        }

        // get products by category 
        if(this.props.match.params.filter === 'kategorije'){
            if(this.state.currentFilter !== 'kategorije'){
                this.state.currentFilter = 'kategorije';
                this.setState({page: 1})
            }
            Service.get('/products/getByCategory',{
                    page: this.page, 
                    perPage: this.state.perPage,
                    categoryId: this.props.match.params.a
                },
                (res)=>{

                    
                    let p = res.data.products;
                    console.log('products', p);
                    this.setState({products: this.sortProducts(p)});
                    this.setState({numberOfProducts: res.data.total_products});
                    // this.setState({pageUpNumber: this.pageUp(), pageDownNumber: this.pageDown()});
            });
            return;
        }

        
        
      }
      getParams = queryString.parse(this.props.location.search);



    changeRating=(rate, name)=>{
        if(Auth.isAuthenticated){
            Service.rateProduct({Auth, productId: name, rating: rate},(res)=>{
            });
        }else{
            alert('Morate biti ulogovani da biste ocenili proizvod!');
        }
    }

    changePage = page => {
        let link;
        if(page.selected){
            link = `${window.location.href.split('?')[0]}?page=${page.selected + 1}`;
           // window.location.href = link;
        }else{
            link = `${window.location.href.split('?')[0]}`;
            
        }
        window.location.href = link;
    }

    render() {
        return(
            <div>
                <div className="left-content">
                    {/* <h3>Kategorije</h3> */}
                    <Categories />
                </div>
                <div className="right-content">
                <h3>{capitalizeFirstLetterCategory(this.props.match.params.b)}</h3>
                    {this.state.products.map(function(item, index){
                        let stickerNew = false;


                        return(
                            <div className="product-container" key={index} >
                                 {/* Stickers */}
                                 {item.categories &&
                                    item.categories.map(function(categories, index){
                                        return(
                                            <span key={index}>
                                                {categories.name === "Novi naslovi" &&
                                                    <Fragment>{stickerNew = true}</Fragment>
                                                }       
                                            </span>
                                        )}, this)
                                    
                                }
                                
                                {(item.sticker === 1 && stickerNew === false  && item.discount !== null) &&
                                    <Sticker classNameProducts="sticker-container-products" text={parseInt(item.discount)+"%"}/>
                                }
                                 {(item.sticker === 1 && stickerNew === true && item.discount !== null) &&
                                    <Sticker classNameProducts="sticker-container-products" text={parseInt(item.discount)+"%"}/>
                                }
                                {(item.sticker === 1 && stickerNew === true && item.discount === null) &&
                                    <Sticker classNameProducts="sticker-container-products" text="NOVO"/>
                                }
                                <h3>{item.title}</h3>
                                <a href={`/proizvod/${item.id}/${item.slug}`}><img alt="" src={Service.images(item.slug.toLowerCase() + '.jpg')} /></a>
                                <div className="product-container-info">
                                    {item.author_link &&
                                        <a href={item.author_link} ><h4>{item.author}</h4></a>
                                    }
                                    {!item.author_link &&
                                        <h4>{item.author}</h4>
                                        
                                    }
                                     { (item.full_price != item.price && item.status != 2) &&
                                        <span>
                                            <p className="old-price">{item.full_price} RSD</p>
                                            <p>{item.price} RSD </p>
                                        </span>
                                     }
                                     {(item.full_price == item.price && item.status != 2) &&
                                        <p>{item.full_price} RSD</p>
                                    }

                                    { item.status == 1 &&
                                        <button type="button" className="add-to-cart-button" onClick={()=>{this.addToCart(item)}}>DODAJ U KORPU</button>
                                    }
                                    { item.status == 2 &&
                                        <fragment>
                                        <p>U pripremi</p>
                                        <button type="button" className="add-to-cart-button wishlist-button" onClick={()=>{this.addToWishlist(item)}}>DODAJ U LISTU ŽELJA</button>
                                        </fragment>
                                    }

                                    { item.status == 3 &&
                                        <button type="button" className="add-to-cart-button sold-out" >RASPRODATO</button>
                                    }
                                    
                                   { item.status != 2 &&
                                        <StarRatings
                                        rating={this.state.rating}
                                        //rating={item.rating}
                                        rating={item.rating}
                                        starDimension="1.6em"
                                        starSpacing=".2em"
                                        starRatedColor="#e6bc00"
                                        changeRating={this.changeRating}
                                        numberOfStars={5}
                                        name={item.id}
                                        />
                                    }

                                </div>
                            </div>
                        );
                        },this)
                    }

                    {this.state.products.length < 1 &&
                        <p className="no-products" >{this.state.noProductsMessage}</p>
                    }
                    {/* <Pager numberOfProducts={this.state.numberOfProducts} currentPage={this.page} perPage={Config.perPage}/> */}
                    <ReactPaginate
                        pageCount={Config.getNumberOfPages(this.state.numberOfProducts)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        previousLabel={'<'}
                        nextLabel={'>'}
                        forcePage={this.page - 1}
                        containerClassName={'pager-container'}
                        activeClassName={'pager-selected'}
                        onPageChange={this.changePage}
                    />
                </div>
                
            </div>

        );
    }

}

export default Products;