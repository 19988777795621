import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import Service from '../components/Service';

function images(image){
    return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
}

class TopProducts extends Component {

    constructor(props){
        super(props);
        this.state = {
            products: []
        }

    }
 
    componentDidMount(){
        Service.getTopProducts({listName: 'hk-top'},
            (res)=>{
                this.setState({products: res.data.products});
            }
        );
    }

    // getTopProducts = () => {
    //     fetch(`${process.env.REACT_APP_API}/products/top`,
    //       {
    //         method: 'post',
    //         headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //         body: JSON.stringify({
              
    //         })
    //        })
    //       .then(res => res.json())
    //       .then(
    //         (result) => {
    //           this.setState({
    //             products: result.data.products
    //           });
    //         },
    //         (error) => {
    //           this.setState({
    //             // isLoaded: true,
    //             // error
    //           });
    //         }
    //       )
    // }

    

    render() {
        return (
            <div className="categories-container top-products-sidebar">
                <h2>Top knjige</h2>
            {this.state.products.map(function(item, index){
                return(
                    <div key={index} className="top-product">
                        
                        <img alt="" src={Service.images(item.slug.toLowerCase() + '.jpg')} width="100" />
                        <div>
                            <h4>{item.author}</h4>
                            <a href={`/proizvod/${item.id}/${item.slug}`}><h3>{item.title}</h3></a>
                            <p className="old-price">{item.full_price} RSD</p>
                            <p>{item.price} RSD</p>
                        </div>
                    
                    </div>
                )
            },this)
            }
            </div>
        );
    }

}

export default TopProducts;
