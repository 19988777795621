import React, { Component } from 'react';


class Fail extends Component{

    constructor(props){
        super(props);
    }

    render(){
        return(
            <div>
                <h1>Transakcija neuspešna!</h1>
                <p>{this.props.match.params.message}</p>
            </div>
        );
    }

}

export default Fail;