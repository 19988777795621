import React, { Component } from 'react';
import ProductViewRelated from '../components/ProductViewRelated';
import Slider from "react-slick";

const sizes = {
    1: '100%',
    2: '50%',
    3: '33%',
    4: '25%',
    5: '20%'
}

class RelatedProducts extends Component {

    render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: this.props.perPage,
      slidesToScroll: this.props.perPage
    };
    return (
      <Slider {...settings}>
          {this.props.products.map(function(item, index){
                        return(
                            // <li key={index} style={{width: sizes[this.props.perPage]}}>
                            <ProductViewRelated item={item} key={index} numbers={true}/>
                            // </li>
                            // <p key={index}>{item.title}</p>   
                        )
                    },this)
                    }
        {/* <div>
          <h3>1</h3>
        </div>
        <div>
          <h3>2</h3>
        </div>
        <div>
          <h3>3</h3>
        </div>
        <div>
          <h3>4</h3>
        </div>
        <div>
          <h3>5</h3>
        </div>
        <div>
          <h3>6</h3>
        </div> */}
      </Slider>
    );
  }


}

export default RelatedProducts;
