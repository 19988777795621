import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class ImageSlider extends Component {

linkTo = (index) => {
    let links = [
        '/proizvod/1121/za-ekstremno-zauzete-zene-suzan-falter',
        '/proizvod/933/izvan-religije-dalaj-lama',
        '/proizvod/928/put-sustine',
        '/proizvod/1106/zlatna-pravila-uspeha',
        '/proizvod/910/ishrana-za-lepotu',
        '/proizvod/1000/sok-od-celera-entoni-vilijam',
        '/proizvod/896/lekovita-moc-etericnih-ulja-erik-zjelinski',
        '/proizvod/897/trazi-i-dobices-ester-i-dzeri-hiks',
        '/proizvod/31/istina-je-u-vama-gordana-biernat',
        '/proizvod/16/kljuc-za-koriscenje-zakona-privlacenja-dzek-kenfild',
        '/proizvod/9/kod-izuzetnog-uma-visen-lakijani',
        '/proizvod/5/moc-osmice-lin-maktagart',
        '/proizvod/18/moc-zene-lujza-hej',
        '/proizvod/17/putovanje-dusa-dr-majkl-njutn',
        '/proizvod/13/savladajte-prepreke-bez-obzira-na-sve-liza-nikols',
        '/proizvod/26/veruj-i-dobices-melisa-alvarez'
    ];

    window.location.href = links[index];
}

    render() {
        return (
            <Carousel showThumbs={false} infiniteLoop={true} interval={6000} autoPlay={true} onClickItem={(e)=>{this.linkTo(e)}}>
                <div>
                    <img src="/images/banners/ekstremno-zauzete-zene-baner.jpg" />
                </div>
                <div>
                    <img src="/images/banners/izvan_religije_baner_horizontalan.jpg" />
                </div>
                <div>
                    <img src="/images/banners/put-sustine-1.jpg" />

                </div>
                <div>
                    <img src="/images/banners/zlatna-pravila-uspeha-2.jpg" />

                </div>
                <div>
                    <img src="/images/banners/ishrana-za-lepotu-i-zdravlje-3.jpg" />

                </div>

                <div>
                    <img src="/images/banners/sok-od-celera-entoni-vilijam-baner.jpg" />

                </div>
                <div>
                    <img src="/images/banners/Etericna-ulja-Erik-Zjelinski-1140x0-000006139167.jpg" />

                </div>
                <div>
                    <img src="/images/banners/Baner-Trazi-i-dobiices-1140x0-000006121211.jpg" />
                </div>
                <div>
                    <img src="/images/banners/Istina-je-u-vama-baner--1140x0-00000673180.jpg" />
                </div>
                <div>
                    <img src="/images/banners/Kljuc-za-koriscenje-zakona-privlacenja-1140x0-00000663121.jpg" />
                </div>
                <div>
                    <img src="/images/banners/Kod-izuzetnog-uma-baner-1140x0-00000694104.jpg" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                    <img src="/images/banners/Moc-osmice-baner-1140x0-00000627136.jpg" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                    <img src="/images/banners/Moc-zene-Lujza-Hej-1140x0-00000663118.jpg" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                    <img src="/images/banners/Putovanje-dusa-baner-1140x0-00000668167.jpg" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                    <img src="/images/banners/Savladajte-prepreke-bez-obzira-na-sve-1140x0-0000062932.jpg" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                    <img src="/images/banners/Veruj-i-dobices-1140x0-000005164129.jpg" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
            </Carousel>
        );
    }
};

export default ImageSlider;
