import React,{ Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import duix from 'duix';

import Home from './views/Home';
import About from './views/About';
import Products from './views/Products';
import Pending from './views/Pending';
import Product from './views/Product';
import Contact from './views/Contact';
import CartPage from './views/CartPage';
import WishlistPage from './views/WishlistPage';
import Krcko from './views/Krcko';
import Howto from './views/Howto';
import Register from './views/Register';
import Rules from './views/Rules';
import Shipping from './views/Shipping';
import News from './views/News';
import NewsSingle from './views/NewsSingle';
import Success from './views/Success';
import Fail from './views/Fail';
import CcFail from './views/CcFail';
import Message from './views/Message';
import Activation from './views/Activation';
import UpdatePassword from './views/UpdatePassword';




import Banners from './components/Banners';
import Header from './components/Header';
import Footer from './components/Footer';
import {Auth} from './components/Auth';
import Modal from './components/Modal';
import ScrollToTop from 'react-router-scroll-top';

import CookieConsent from "react-cookie-consent";



// da se upisuje produkt iz komponente i da ga upisuje u lokalu (props vrv) i da iscitava

const updateWhishlist=()=>{

}

const checkLoginStatus = () => {
  if (localStorage.getItem('token') && localStorage.getItem('userId')) {
    Auth.isAuthenticated = true;
    Auth.token = localStorage.getItem('token');
    Auth.userId = localStorage.getItem('userId');
    Auth.name = localStorage.getItem('name');
    Auth.email = localStorage.getItem('email');
    duix.set('registration', 1);
  }else {
      Auth.isAuthenticated = false;
  }
}
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/registracija',
          state: { from: props.location }
        }} />
  )} />
)

{checkLoginStatus()}

class App extends Component {

    constructor(props){
        super(props);
        this.header = React.createRef();
    }

    componentDidMount(){
        checkLoginStatus();

        // Add This load script
        // setTimeout( () => {
        // var addthisScript2 = document.createElement('script');
        // addthisScript2.setAttribute('src', 'http://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5d728325401139e1')
        // if (document.body) document.body.appendChild(addthisScript2)
        // },10);
    }

    updateCartDisplay=()=>{
       // this.header.current.updateCartDisplay();
    }

    updateRegistration=()=>{
        this.header.current.updateRegistration();
    }

    

  render() {
    return (
        <div>
        
      
        <Router>
            
          <Header history={this.props.history} ref={this.header}/> 
          <div className="wrapper">
              <ScrollToTop>
          <Route exact path="/" component={Home} />
          <Route path="/o-nama" component={About} />      
          <Route path="/proizvodi/:filter?/:a?/:b?" component={Products} />
          {/* <Route path="/proizvodi/:filter?/:a?/:b?" render={(props) => <Products {...props} updateCartDisplay={this.updateCartDisplay} />} /> */}
          {/* <Route path="/proizvod/:id/:slug?" component={Product} /> */}
          <Route path="/proizvod/:id/:slug?" render={(props) => <Product {...props} updateCartDisplay={this.updateCartDisplay} />} />
          <Route path="/u-pripremi" component={Pending} />
          <Route path="/kontakt" component={Contact} />
          {/* <Route path="/korpa" component={CartPage} /> */}
          <Route path="/korpa" render={(props) => <CartPage {...props} updateCartDisplay={this.updateCartDisplay} />} />
          <PrivateRoute path="/lista-zelja" component={WishlistPage} />
          <Route path="/krcko" component={Krcko} />
          <Route path="/kako-kupovati" component={Howto} />
          {/* <Route path="/registracija" component={Register} /> */}
          <Route path="/registracija/:message?" render={(props) => <Register {...props} updateRegistration={this.updateRegistration} />} />
          <Route path="/pravila" component={Rules} />
          <Route path="/dostava" component={Shipping} />
          <Route path="/zanimljivosti" component={News} />
          <Route path="/vest/:id/:slug?" component={NewsSingle} />
          <Route path="/message/:message?" component={Message} />
          <Route path="/aktivacija/:activationCode" component={Activation} />
          <Route path="/promenalozinke/:activationCode/" component={UpdatePassword} />
          <Route path="/success/:type?/:oid?/:ammount?/:tid?" component={Success} />
          <Route path="/fail" component={Fail} />
          <Route path="/ccfail/:message?" component={CcFail} />
          <Route path="/banners" component={Banners} />
            <CookieConsent
                location="bottom"
                buttonText="Prihvatam"
                cookieName="hkCookie"
                style={{ background: "#eb4200", fontSize: '1em',  boxShadow: '0px -5px 10px rgba(0, 0, 0, .3)' }}
                buttonStyle={{ color: "#4e503b", fontSize: "16px", fontWeight: 'bold' }}
                expires={150}
            >
                Sajt koristi kolačiće (cookies) koji ne sadrže lične podatke i služe za poboljšanje korisničkog iskustva naše stranice. Prisutnost na sajtu, podrazumeva da se posetioci slažu sa korišćenjem ovih kolačića.
Za više detalja o zaštiti podataka pročitajte u <a href="/pravila" style={{ textDecoration: 'underline'}} >Polisa privatnosti</a>.
                
            </CookieConsent>
          <Footer />
          <Modal />
          </ScrollToTop>
          {/* <button onClick={this.updateCart}>click</button> */}
          </div>
        </Router>
      </div>
    );
  }
}
export default App;
