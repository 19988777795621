import Service from '../components/Service';

export const Auth = {
  isAuthenticated: false,
  userId: false,
  name: false,
  token: false,
  lastProductsPage: false,
  
  authenticate(password, cb, cb2) {

    Service.get('/users/login',{
        email: this.email,
        password: password
    },(res)=>{
        if(res.success){

        		this.isAuthenticated = true;
                this.userId = res.data.id;
                this.name = res.data.name;
                this.token = res.data.token;

                localStorage.setItem('token', res.data.token);
                localStorage.setItem('userId', res.data.id);
                localStorage.setItem('name', res.data.name);
                localStorage.setItem('email', this.email);


                cb(res);
        		//setTimeout(cb, 100);
        		
        	}else{

        		this.isAuthenticated = false;
                cb2(res.info);

        	}
    });

  	// fetch(`${process.env.REACT_APP_API}/users/login`, 
	//   	{
	// 	   method: 'post',
	// 	   headers: {'Content-Type':'application/x-www-form-urlencoded'},
	// 	   body: JSON.stringify({
	// 	        email: this.email,
    //             password: password
	// 		})
	//    	})
	// 	.then(res => res.json())
    //   .then(
    //     (result) => {
    //     	if(result.success){
    //     		this.isAuthenticated = true;
    //             this.userId = result.data.id;
    //             this.name = result.data.name;
    //             this.token = result.data.token;

    //             localStorage.setItem('token', result.data.token);
    //             localStorage.setItem('userId', result.data.id);
    //             localStorage.setItem('name', result.data.name);

    //     		setTimeout(cb, 100);
        		
    //     	}else{
    //     		this.isAuthenticated = false;
    //             cb2(result.info);
    //             //return result.info;
    //     	}
    //     },

    //     (error) => {
    //     }
    //   )


    
  },

  setLastPage(page){
      this.lastProductsPage = page;
      localStorage.setItem('lastProductsPage', this.lastProductsPage);
  },

    logout(cb=null) {

        Service.get('/users/logout',{
            userId: this.userId,
            token: this.token
        },(res)=>{

            this.isAuthenticated = false;
            this.userId = false;
            this.name = false;
            this.token = false;

            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('name');
            localStorage.removeItem('email');


            if(cb){
                cb(res);
            }
        })

  	// fetch(`${process.env.REACT_APP_API}/users/logout`, 
	//   	{
	// 	   method: 'post',
	// 	   headers: {'Content-Type':'application/x-www-form-urlencoded'},
	// 	   body: JSON.stringify({
    //            Auth: this,
	// 	        userId: this.userId,
    //             token: this.token
	// 		})
	//    	})
	// 	.then(res => res.json())
    //   .then(
    //     (result) => {
    //         this.isAuthenticated = false;
    //         this.userId = false;
    //         this.name = false;
    //         this.token = false;
    //         localStorage.removeItem('token');
    //         localStorage.removeItem('userId');
    //         localStorage.removeItem('name');
    //         if(cb){
    //             cb(result);
    //         }
    //     },

    //     (error) => {
    //     }
    //   )


    
  },

  
  signout(cb) {
    this.logout();
    this.isAuthenticated = false
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('name');
    localStorage.removeItem('email',);
    setTimeout(cb, 100)
  }
}