import React, { Component } from 'react';
import ProductView from '../components/ProductView';
import Slider from "react-slick";

const sizes = {
    1: '100%',
    2: '50%',
    3: '33%',
    4: '25%',
    5: '20%'
}

class ProductSlider extends Component {

    render() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5, //this.props.perPage,
        slidesToScroll: 5, //this.props.perPage,
        autoplay: true,
        initialSlide: 0,
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ]
    };
    return (
      <Slider {...settings}>
          {this.props.products.map(function(item, index){
                        return(
                            // <li key={index} style={{width: sizes[this.props.perPage]}}>
                            <div className="top-products-container" >
                                <div className="top-position" >{index+1}</div>
                            <ProductView item={item} key={index} />
                            </div>
                            // </li>
                            // <p key={index}>{item.title}</p>   
                        )
                    },this)
                    }
        {/* <div>
          <h3>1</h3>
        </div>
        <div>
          <h3>2</h3>
        </div>
        <div>
          <h3>3</h3>
        </div>
        <div>
          <h3>4</h3>
        </div>
        <div>
          <h3>5</h3>
        </div>
        <div>
          <h3>6</h3>
        </div> */}
      </Slider>
    );
  }


}

export default ProductSlider;
