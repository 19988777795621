import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component {

    constructor(props){
        super(props);
        this.state = {
            subscribed: false
        }

    }

    handleSubscription = () => {
        let s = this.state.subscribed;
        s = !s;
        this.setState({
            subscribed: s
        });
    }

    render(){
        return(
            <div className="footer">
                <div className="footer-top">
                    <img alt="" src="/logo-sign.png" />
                    <h3>HARMONIJA</h3>
                    <p>DOBROVOLJAČKA 10/I, 11080 ZEMUN, SRBIJA +381 11 4202544 | +381 11 4202545</p>
                    <ul>
                        <li><Link to="/o-nama">O nama</Link></li>
                        <li><Link to="/kako-kupovati">Kako kupovati</Link></li>
                        <li><Link to="/pravila">Pravila korišćenja</Link></li>
                        <li><Link to="/dostava">Dostava</Link></li>
                        <li><Link to="/kontakt">Kontakt</Link></li>
                    </ul>
                    <p>Copyright © Publik Praktikum | <Link to='/kako-kupovati' >Polisa privatnosti</Link></p>
                </div>
                <div className="footer-bottom-left"></div>

                {/* bank card images */}
                <div className="footer-logos">
                    <div>
                        <img alt="" src="/images/cards.png" />
                    </div>
                    <div>
                        <a href="https://www.bancaintesa.rs/pocetna.1.html">
                        <img alt="" src="/images/bintesa.png" />
                        </a>
                    </div>
                    <div>
                        <a href="https://rs.visa.com/rs/rs-rs/protectedeverywhere/index.html">
                            <img alt="" src="/images/learn1.jpg" />
                        </a>
                        <a href="https://www.mastercard.us/en-us.html">
                            <img alt="" src="/images/learn2.jpg" />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;