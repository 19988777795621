import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import duix from 'duix';
import {Animated} from "react-animated-css";

class Modal extends Component {

    constructor(props){
        super(props);
        this.state = {
            display: 'block',
            modalMessage: 'message',
            visible: false
        }

    }

    unsubscribe = []
 
    componentDidMount(){
        
        this.unsubscribe[0] = duix.subscribe('modal', (value) => {
             this.showModal(value)
        });
        
    }

    componentWillUnmount(){
        //clearInterval(this.interval);
        this.unsubscribe[0]();
    }

    showModal=(message)=>{
        this.setState({modalMessage: message, visible: true});
        setTimeout(()=>{this.hideModal()}, 4000);
    }

    hideModal=()=>{
        this.setState({visible: false});
    }


    render() {
        return (
            <Animated animateOnMount={false}  animationIn="fadeIn" animationOut="fadeOut" isVisible={this.state.visible}>
            <div className="modal" style={{display: this.state.display}}>
                <button onClick={this.hideModal}>X</button>
                <p>{this.state.modalMessage}</p>
            </div>
            </Animated>
        );
    }

}

export default Modal;
