import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import Service from './Service';



class Categories extends Component {

    constructor(props){
        super(props);
        this.state = {
            numberOfProducts: 0,
            categories: []
        }

    }
 
    componentDidMount(){
        this.getAllCategories();

    }

    getAllCategories = () => {
      Service.get('/categories/all',{
          page: 1,
          perPage: 50,
          filter: ''
      },(res)=>{
        this.setState({categories: res.data});
        if(window.screen.width > 720){
          this.openFirstCategory();
        }
      });
        // fetch(`${process.env.REACT_APP_API}/categories/all`,
        //   {
        //     method: 'post',
        //     headers: {'Content-Type':'application/x-www-form-urlencoded'},
        //     body: JSON.stringify({
        //       //userId: this.state.userId
        //       page: 1,
        //       perPage: 50,
        //       filter: ''
        //     })
        //    })
        //   .then(res => res.json())
        //   .then(
        //     (result) => {
        //       this.setState({
        //         categories: result.data
                
        //       },()=>{
        //           if(window.screen.width > 720){
        //             this.openFirstCategory();
        //           }
                  
        //           }
        //       );
        //     },
        //     (error) => {
        //       this.setState({
        //         // isLoaded: true,
        //         // error
        //       });
        //     }
        //   )
    }

    handleSubcategories = (event) => {

        let e = event.currentTarget.parentNode.getElementsByClassName('subcategories-container')[0];
        if(e.style.display === 'none' || !e.style.display){
            e.style.display = 'block';
            event.target.classList.add('arrow-open');
            event.target.classList.remove('arrow-closed');
        }else{
            e.style.display = 'none';
            event.target.classList.add('arrow-closed');
            event.target.classList.remove('arrow-open');
        }
    }

    openFirstCategory=()=>{
        let e = document.getElementById(0);
        document.getElementById('sub'+0).style.display = 'block';
        e.classList.add('arrow-open');
        e.classList.remove('arrow-closed');
    }

    render() {
        return (
            <div className="categories-container">
            {this.state.categories.map(function(item, index){
                if(item.subcategories.length > 0){
                return(
                    <div className="categories">
                    <h3 className="arrow-closed" id={index} onClick={this.handleSubcategories}>{item.name}</h3>
                    <div className="subcategories-container" id={'sub'+index}>
                    {item.subcategories.map(function(subcategory,i){
                            return(
                                <a href={`/proizvodi/kategorije/${subcategory.id}/${subcategory.name.replace(/ /g,'-').toLowerCase()}`} key={i+'sub'}>{subcategory.name}</a>
                            )
                        })
                    }
                    </div>
                    </div>
                )
                }
            },this)
            }
            </div>
        );
    }

}

export default Categories;
