import React, { Component } from 'react';
import Service from '../components/Service';
import CartProduct from '../components/CartProduct';
import {Auth} from '../components/Auth';
import duix from 'duix';

function images(image){
    return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
}

class WishlistPage extends Component {

//komponenta CartProduct(props) za listu prikazivanja iz for petlje upakovati logiku u komponentu i samo izlistavati listu komponenti


constructor(props){
    super(props);
    this.state = {
        products: []
    }
}



componentDidMount(){

    
    this.updateWishlist();
}

updateWishlist=()=>{
    Service.getWishlist({Auth},(res)=>{
        this.setState({products: res.data.products});
        duix.set('wishlistItems', 1);
    },this);
}

removeProduct(id){
    Service.removeFromWishList({Auth, productId: id},()=>{
        this.updateWishlist();
    });
}


render() {

return (

    this.state.products.map(function(item, index){
                return(
                    //  <CartProduct key={index} id={item.title} value={item.author}/>  
                        <div className="cart-product">
                    <div className="image"><img src={Service.images(item.slug + '.jpg')} /></div>
                    <div className="title">{item.title}</div>
                    <div className="quantity">
                       
                    </div>
                    <div className="price"></div>
                    <div className="trash"><button onClick={()=>{this.removeProduct(item.id)}}>X</button></div>
                </div> 
                )
            }, this)
        

        );
    }


}

export default WishlistPage;