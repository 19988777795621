import React, { Component,Fragment  } from 'react';
import { Cart } from '../components/Cart';
import Categories from '../components/Categories';
import Service from '../components/Service';
import { Auth } from '../components/Auth';
import duix from 'duix';
import ProductSlider from '../components/ProductSlider';
import RelatedProducts from '../components/RelatedProducts';
import {Config} from '../components/Config';
import AddThis from '../components/AddThis';
import renderHTML from 'react-render-html';
import ReactImageMagnify from 'react-image-magnify';
import Slider from "react-slick";
import Sticker from '../components/Sticker';

import StarRatings from 'react-star-ratings';

import ReactGA from 'react-ga';

// function images(image){
//     return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
// }

const Info = props => (
  
    <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
 
);

class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            comment: '',
            comments: [],
            relatedProducts: [],
            helpClass: 'help-hidden',
            pending: false,
            notify: false,
            productsCount: 3,
            imageWidth: 200,
            images: [],
            currentImage: '',
            selectedImage: 0,
            productImages: [],
            modalImagesVisible: false
        }
    }

    componentDidMount() {

        ReactGA.initialize(Service.gaCode);
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.updateProducts();
        this.getComments();
        this.updateRelatedProducts();
        this.getNotification();
        
      }

    zoomImage=()=>{
        if(this.state.imageWidth === 200){
            this.setState({imageWidth: 350});
        }else{
            this.setState({imageWidth: 200});
        }
    }
    resetImageZoom=()=>{
        this.setState({imageWidth: 200});
    }

    componentWillReceiveProps(nextProps){
        setTimeout(()=>{
            this.updateProducts();
            this.getComments();
            this.updateRelatedProducts();
            this.getNotification();
        },10);

        
    }

    addToCart(product){
      Cart.addToCart(product);
      Cart.getCart();
      this.props.updateCartDisplay();
    }

    removeOneFromCart(product){
      Cart.removeOneFromCart(product);
      Cart.getCart();

    }
  
    removeFromCart(product){
      Cart.removeFromCart(product);
      Cart.getCart();
    }

    updateProducts = () => {
        Service.getProductById({
            id: this.props.match.params.id, 
            } ,(products)=>{
                this.setState({products: products, currentImage: Service.images(products[0].slug.toLowerCase() + '.jpg')},()=>{
                    this.getSecondaryImages();
                });
                if(parseInt(products[0].status) === 2){
                    this.setState({pending: true});
            }
        });
      }

    updateRelatedProducts=()=>{
        Service.get('/products/relatedProducts',{productId: this.props.match.params.id},(res)=>{
            
            let productsCount = 0;
            if(res.data.products.length < 5){
                    productsCount = res.data.products.length;
                }else{
                    productsCount = 5;
                }
                if(window.screen.width < 720){
                    productsCount = 3;
                }
            this.setState({productsCount: productsCount});
            this.setState({relatedProducts: res.data.products});
        })
    }

    getNotification=()=>{
        setTimeout(()=>{
        Service.get('/products/getNotification',{productId: this.props.match.params.id},(res)=>{
            if(res.success){
                this.setState({notify: res.notify});
            }
        })
        },100);
    }

    // changeNotification=()=>{
        
    // }

    changeImage=(event)=>{
        event.stopPropagation();
        let i = this.state.selectedImage;
        // console.log('next', i);
         console.log(event.currentTarget.id);
        if(event.target.id === 'next'){
            i ++;
            console.log('ln',this.state.productImages.length, i)
            if(i >= this.state.productImages.length){
                i = 0;
                
            }
            this.setState({selectedImage: i});
            return;
        }
        if(event.target.id === 'prev'){
            i --;
            if(i < 0){
                i = this.state.productImages.length -1;
            }
            this.setState({selectedImage: i});
            return;
        }

        // if image thumb clicked, change selected image and show popup
        // console.log('ee',event.currentTarget.id);
        // this.slider.slickGoTo(event.currentTarget.id);
        // this.setState({selectedImage: event.currentTarget.id, modalImagesVisible: 'block'});
        console.log('ee',event.currentTarget.id);
        this.slider.slickGoTo(event.currentTarget.id);
        this.setState({selectedImage: event.currentTarget.id, modalImagesVisible: 'block'});
        
    }

    hideImagePreview=(event)=>{
        event.stopPropagation();
        // this.setState({modalImagesVisible: 'none'});
        if(event.target.id === 'image-preview'){
            this.setState({modalImagesVisible: 'none'});
        }
        //console.log(event.target.id);
    }

    notifyMe=(productId)=>{
        if(!Auth.isAuthenticated){
            alert('Morate biti ulogovani kako biste koristili obaveštenja o knjigama.')
        }else{
            Service.get('/products/notify',{productId: this.props.match.params.id, storeId: 1},()=>{
                this.getNotification(); 
            })
        }
    }

    addToWishlist= () => {
        if(Auth.isAuthenticated){
        Service.addToWishlist({
            productId: this.props.match.params.id, 
            Auth
            },()=>{
                let today = new Date();
                //duix.set('', 1);
                duix.set('wishlistItems', 1);
                duix.set('modal', 'Proizvod je dodat u listu želja.');
                
                
            });
        }else{
            alert('Morate biti ulogovani da biste koristili listu želja.');
        }
    }



    handleComment = (event) => {
        this.setState({comment: event.target.value});
    }

    addComment = () => {
        if(!Auth.isAuthenticated){
            alert('Morate biti ulogovani da biste postavili komentar.');
            return;
        }
        Service.addComment({Auth, productId: this.props.match.params.id, text: this.state.comment},
            (result)=>{alert('Komentar je uspešno poslat. Vaš komentar će biti vidljiv kada ga odobri administrator.')}

        )
    } 

    getComments = () => {
        Service.getComments({productId: this.props.match.params.id},
            (result)=>{
                if(result.data.comments){
                    this.setState({comments: result.data.comments})
                }
            }
            
        );
    } 

    changeRating=(rate, name)=>{
        if(Auth.isAuthenticated){
            Service.rateProduct({Auth, productId: name, rating: rate},(res)=>{
            });
        }else{
            alert('Morate biti ulogovani da biste ocenili proizvod!');
        }
    }



    toggleHelp=()=>{
        if(this.state.helpClass === 'help-hidden'){
            this.setState({helpClass: 'help-visible'});
        }else{
            this.setState({helpClass: 'help-hidden'});
        }
    }

    getSecondaryImages=()=>{
        Service.get('/products/getImages',{
            productId: this.props.match.params.id
            },(res)=>{
                if(res.success){
                    this.setState({images: res.data.images},()=>{
                        this.updateProductImages();
                    })
                    
                }
            });
    }

    updateProductImages=()=>{
        let i = [];
        i.push(this.state.currentImage);
        this.state.images.map(function(item){
            i.push(Service.secondaryImages + item.image_name);
        })
        this.setState({productImages: i});
    }


    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 200,
            slidesToShow: 1,
            slidesToScroll: 1,
            // initialSlide: this.state.selectedImage,
            // nextArrow: <div>{'>'}</div>,
            // prevArrow: <div>{'<'}</div>
        };

        return(
            
            <div>
                <div className="left-content">
                    {/* <h3>Kategorije</h3> */}
                    <Categories />
                </div>
                <div className="right-content">
                <h3>Knjige</h3>
                {this.state.products.map(function(item, index){
                     let stickerNew = false;

                    return(
                        
                        <div key={index}>
                            <div className="product-left">
                                {/* <img alt="" onClick={this.zoomImage} onMouseLeave={()=>{this.resetImageZoom()}} src={Service.images(item.slug.toLowerCase() + '.jpg')} width={this.state.imageWidth} /> */}
                                {/* <ReactImageMagnify {...{
                                    // enlargedImagePosition: 'over',
                                    enlargedImageContainerDimensions: {width: '145%',height: '120%'},
                                    smallImage: {
                                        alt: item.title,
                                        isFluidWidth: true,
                                        src: Service.images(item.slug.toLowerCase() + '.jpg')
                                    },
                                    largeImage: {
                                        src: Service.images(item.slug.toLowerCase() + '.jpg'),
                                        width: 768,
                                        height: 1024
                                    }
                                }} /> */}
                                    {/* Stickers */}
                                    {item.categories.map(function(categories, index){
                                    return(
                                        <span key={index}>
                                            {categories.name === "Novi naslovi" &&
                                                <Fragment>{stickerNew = true}</Fragment>
                                            }       
                                        </span>
                                    )}, this)
                                }
                                
                                {(item.sticker === 1 && stickerNew === false  && item.discount !== null) &&
                                    <Sticker classNameProducts="sticker-container-product" text={parseInt(item.discount)+"%"}/>
                                }
                                 {(item.sticker === 1 && stickerNew === true && item.discount !== null) &&
                                    <Sticker classNameProducts="sticker-container-product" text={parseInt(item.discount)+"%"}/>
                                }
                                {(item.sticker === 1 && stickerNew === true && item.discount === null) &&
                                    <Sticker classNameProducts="sticker-container-product" text="NOVO"/>
                                }
                                <div className='product-main-image'>
                                    <img alt="" onClick={this.changeImage} id={0} src={this.state.currentImage}  width={this.state.imageWidth} /> 
                                </div>
                            </div>
                            <div className="product-mid">
                                <h1 className="product-right-title">{item.title}</h1>
                                <div className="product-authors">
                                    {item.authors.map(function(author, index){
                                            return(
                                                <span key={index} className="authors-span">
                                                    {author.link &&
                                                        <a href={author.link}><h3>{author.name}</h3></a>
                                                    }
                                                    {!author.link &&
                                                        <h3>{author.name}</h3>
                                                    }
                                                </span>
                                            )}, this)
                                        }
                                        </div>
                                {/* <h3>{item.author}</h3> */}
                                
                                <StarRatings
                                    rating={this.state.rating}
                                    //rating={item.rating}
                                    rating={item.rating}
                                    starDimension="1.6em"
                                    starSpacing=".2em"
                                    starRatedColor="#e6bc00"
                                    changeRating={this.changeRating}
                                    numberOfStars={5}
                                    name={item.id}
                                />

                                <div className="secondary-images-cont secondary-image-cont-mobile">
                                    {this.state.images.map(function(item, index){
                                        return(
                                            <div className='secondary-image-cont' key={index} onClick={this.changeImage} id={index + 1}>
                                                <img alt="" className='secondary-image' onClick={this.changeImage} id={index + 1} src={Service.secondaryImages + item.image_name} />
                                            </div>
                                        )}, this)
                                    }
                                </div>

                                <AddThis url={window.location.href} title={ 'Harmonija knjige | '+item.title } />
                                { this.state.pending === false &&
                                    <div className="product-container-info">
                                        <Info label="povez" value={item.binding} />
                                        <Info label="broj strana" value={item.number_of_pages} />
                                        <Info label="prevodilac" value={item.translator} />
                                        <Info label="pismo" value={item.letter} />
                                        <Info label="format" value={item.dimension} />
                                        <Info label="isbn" value={item.isbn} />
                                    </div>
                                }
                            
                            </div>
                        
                            <div className="product-right">
                                { item.status == 1 &&
                                <div>
                                    <p className="old-price">{item.full_price} RSD</p>
                                    <h2>{item.price} RSD</h2>
                                
                                
                                    <button type="button" className="add-to-cart-button" onClick={()=>{this.addToCart(item)}}>DODAJ U KORPU</button>
                                </div>
                                }
                                { item.status == 3 &&
                                    <div>
                                        <button type="button" className="add-to-cart-button sold-out" >RASPRODATO</button>
                                        <p className="notify-me"><input type="checkbox" onChange={()=>{this.notifyMe(item.id)}} checked={this.state.notify} /> Obavesti me kad ovaj naslov bude dostupan.</p>
                                    </div>
                                }
                                { item.status == 2 &&
                                    <div>
                                        {/* <button type="button" className="add-to-cart-button sold-out" >RASPRODATO</button> */}
                                        <p>Ovaj naslov trenutno je<br />U PRIPREMI</p>
                                        <p className="notify-me"><input type="checkbox" onChange={()=>{this.notifyMe(item.id)}} checked={this.state.notify} /> Obavesti me kad ovaj naslov bude dostupan.</p>
                                    </div>
                                }

                                <button type="button" className="add-to-cart-button wishlist-button" onClick={()=>{this.addToWishlist(item)}}>DODAJ U LISTU ŽELJA</button>
                                <hr />
                                <p className="product-right-toggle" onClick={this.toggleHelp}>Potrebna vam je pomoć?</p>
                            </div>

                            <div className="secondary-images-cont secondary-image-cont-desktop">
                                    {this.state.images.map(function(item, index){
                                        return(
                                            <div className='secondary-image-cont' key={index} onClick={this.changeImage} id={index + 1}>
                                                <img alt="" className='secondary-image' onClick={this.changeImage} id={index + 1} src={Service.secondaryImages + item.image_name} />
                                            </div>
                                        )}, this)
                                    }
                            </div>
                            <div className="image-preview" style={{display: this.state.modalImagesVisible}} id='image-preview' onClick={this.hideImagePreview}>
                            <span className="popup-slider-cont" id='image-preview-body'>
                                <Slider ref={slider => (this.slider = slider)} initialSlide={this.state.selectedImage} {...settings}>
                                    {this.state.productImages.map(function(item, index){
                                        return(
                                        <img alt="" src={item} />
                                        )

                                    })

                                    }
                                </Slider>
                            </span>
                            </div>

                            <div className="product-bottom">
                                <h2>Opis:</h2>
                                    {renderHTML(item.description)}
                                    {/* <p style={{whiteSpace: 'pre-wrap'}}>{item.description}</p> */}
                                    <hr />
                                <div>
                                    <h2>Iz iste oblasti</h2>
                                    <RelatedProducts products={this.state.relatedProducts} perPage={this.state.productsCount} />
                                    {/* <ProductSlider products={this.state.relatedProducts} perPage={this.state.productsCount} /> */}
                                </div>
                                <div className="comments">
                                    
                                    <h2>Komentari</h2>
                                    {this.state.comments.map(function(item, index){
                                            return(
                                                <div className='comment' key={index}>
                                                    {/* <h4>{item.name}</h4>
                                                    <h5>{item.created_at}</h5> */}
                                                    <p>{item.text}</p>
                                                    <h5>{item.name} <span>{item.created_at.slice(0,10)}</span></h5>
                                                </div>
                                            )
                                        },this)
                                    }
                                    
                                    <textarea placeholder="Napišite komentar..." cols="60" rows="5" onChange={this.handleComment}></textarea>
                                    <br/>
                                    <button type="button" className="add-to-cart-button" onClick={this.addComment}>Pošalji</button>
                                </div>
                            </div>
                        </div>

                        
                );
                    },this)
                  }
                    <div className="help-button" onClick={this.toggleHelp}>?</div>
                    <div className={this.state.helpClass}>
                        <div className="help-content">
                            <h3>Potrebna vam je pomoć?</h3>

                            <p>Ukoliko imate neki
                            problem pri kupovini
                            ili želite više informacija
                            kontaktirajte nas.</p>

                            <p>Pozovite nas<br />
                            011/4202544<br />
                            011/4202545</p>

                            Pišite nam<br />
                            office@harmonijaknjige.rs
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}

export default Product;