import React, { Component } from 'react';
import ProductView from '../components/ProductView';
import Slider from "react-slick";

const sizes = {
    1: '100%',
    2: '50%',
    3: '33%',
    4: '25%',
    5: '20%'
}

class ProductSlider extends Component {

    render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: this.props.perPage,
      slidesToScroll: this.props.perPage,
      autoplay: true
    };
    return (
      <Slider {...settings}>
          {this.props.products.map(function(item, index){
                        return(
                            <ProductView item={item} key={index} numbers={true}/> 
                        )
                    },this)
                    }
      </Slider>
    );
  }


}

export default ProductSlider;
