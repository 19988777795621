import React, { Component } from 'react';
import { useSwipeable, Swipeable } from 'react-swipeable'

// var moving = false;

class Slider extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            current: 0, 
            position: 0,
            animation: 'vd-slider-animation',
            moving: false,
            duration: .5,
            oldDuration: .5,
            perPage: 5,
            auto: false
        }
        this.sliderElement = React.createRef();
    }

    interval;

    componentDidMount(){
        if(window.screen.width < 980){
            this.setState({perPage: this.props.responsivePerPage, width: this.sliderElement.current.offsetWidth / this.props.responsivePerPage});
        }else{
            this.setState({perPage: this.props.perPage, width: this.sliderElement.current.offsetWidth / this.state.perPage});
        }
        // this.setState({width: this.sliderElement.current.offsetWidth / this.state.perPage});
        if(this.props.duration){
            this.setState({duration: this.props.duration},()=>{
                this.setState({oldDuration: this.state.duration});
            });
        }else{
            this.setState({oldDuration: this.state.duration});
        }
        if(this.props.auto && this.props.auto === true){
            this.interval = setInterval(()=>{this.next()}, this.props.interval ? this.props.interval : 3000);
        }
        
    }

    componentWillUnmount(){
        clearInterval(this.interval);
    }

    returnAnimation=(cb)=>{

            setTimeout(()=>{
                //this.setState({animation: 'vd-slider-animation'})
                this.setState({duration: this.state.oldDuration},()=>{
                    cb();
                });
                
            },100);
    }

    calculatePosition=()=>{

        // move the position
        this.setState({position: this.state.current * this.state.width * -1}, ()=>{

            // reset the move stopper after the move
            setTimeout(()=>{
                    // this.setState({moving: false});
                    this.state.moving = false;
                },(this.state.oldDuration * 1000) + 100);
            // if off border return to original position
            // if(this.state.current >= this.props.children.length){
            //     setTimeout(()=>{
            //         console.log('move');
            //         this.setState({duration: 0, current: 0},()=>{
            //             this.setState({position: this.state.current * this.state.width * -1}, ()=>{
            //                 console.log('moved');
            //                 this.returnAnimation();
            //                 moving = false;
            //             })
            //         });
            //     },(this.state.oldDuration * 1000) + 50);
            // }else{
            //     setTimeout(()=>{
            //         // this.setState({moving: false});
            //         moving = false;
            //     },(this.state.oldDuration * 1000) + 100);
            // }

            // if(this.state.current < 1){
            //     setTimeout(()=>{
            //         console.log('move');
            //         this.setState({duration: 0, current: this.props.children.length},()=>{
            //             this.setState({position: this.state.current * this.state.width * -1}, ()=>{
            //                 console.log('moved');
            //                 this.returnAnimation();
            //                 moving = false;
            //             })
            //         });
            //     },(this.state.oldDuration * 1000) + 50);
            // }else{
            //     setTimeout(()=>{
            //         // this.setState({moving: false});
            //         moving = false;
            //     },(this.state.oldDuration * 1000) + 100);
            // }

            
            
        });

    }

    previous=()=>{
        
        if(!this.state.moving){
            this.state.moving = true;
            if(this.state.current < 1){

                    this.setState({duration: 0, current: this.props.children.length},()=>{
                        this.setState({position: this.state.current * this.state.width * -1}, ()=>{

                            this.returnAnimation(()=>{
                                this.setState({current: this.state.current - 1},()=>{
                                    this.calculatePosition();
                                });
                            });
                        })
                    });
            }else{
                this.setState({current: this.state.current - 1},()=>{
                    this.calculatePosition();
                });
            }
        }
    }

    next=()=>{

        if(!this.state.moving){
            this.state.moving = true;
            if(this.state.current >= this.props.children.length){
                    this.setState({duration: 0, current: 0},()=>{
                        this.setState({position: this.state.current * this.state.width * -1}, ()=>{
                            this.returnAnimation(()=>{
                                this.setState({current: this.state.current + 1},()=>{
                                    this.calculatePosition();
                                });
                            });
                        })
                    });
            }else{
                this.setState({current: this.state.current + 1},()=>{
                    this.calculatePosition();
                });
            }
        }
    }

    swipe=(event)=>{

        if(event.dir === 'Left'){
            this.next();
        }
        if(event.dir === 'Right'){
            this.previous();
        }
    }

    render(){
        return(
            <div className="vd-slider" ref={this.sliderElement}>
                <Swipeable onSwiped={this.swipe}>
                    <div className={"vd-slider-container " + this.state.animation} style={{left: this.state.position, transitionProperty: 'left', transitionDuration: this.state.duration+'s'}}>
                        {this.props.children.map(function(item, index){
                                return(
                                    <div className="vd-slider-element" style={{width: this.state.width}}>
                                    {item}
                                    </div>
                                )
                            }, this  

                        )}
                        {this.props.children.map(function(item, index){
                            if(index < this.state.perPage){
                                return(
                                    <div className="vd-slider-element" style={{width: this.state.width}}>
                                    {item}
                                    </div>
                                )
                            }
                            }, this  

                        )}
                        
                        
                    </div>
                </Swipeable>
                <span className="vd-slider-button button-previous" onClick={this.previous}><img  onClick={this.previous} src="arrow.png" /></span>
                <span className="vd-slider-button button-next" onClick={this.next}><img onClick={this.next} src="arrow.png" /></span>
            </div>
        );
    }

}

export default Slider;